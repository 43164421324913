// Here you can add other styles
//.d-md-down-none{color:#ff004c;}
// :root {
//   --header_height: 70px;
//   --table_footer_height: 60px
// }

.c-sidebar-brand-full {
  color: gray("100");
  background-color: #1E5423;
}
  
.light-buttons {
  color: #2e8337;
}
  
.quantity-filter {
  margin-bottom: 1rem;
  font-weight: bold;
}

.TotalRowStatements{
  background-color: grey;
}

.c-sidebar-brand-whole {
  background-color: #26682d;
}
.MuiDataGrid-columnsContainer {
  background-color: #2e8337;
  color: #ffffff;
}
.headlines {
  color: #26682d;
  @media screen and (max-width: 992px) {
    font-size: 70px;
  }
}

.MuiDataGrid-colCellCheckbox .MuiSvgIcon-root{
  color: #ffffff;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator{
  opacity: 0;
}

.prefix__cls-1 {
  fill:#468532;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
  font-weight: bold;
}

.MuiBadge-colorPrimary{
  background-color: #1E5423 !important;
}

.MuiFormControlLabel-label{
  font-size: 0.75rem !important;
}

.btn-money-group {
 border: solid 1px !important;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 15px;
}
.float-right:focus {
  outline: none !important;
}

.MuiTableCell-stickyHeader {
  background-color: #2e8337 !important;
}

.pdfTable_head_row_cell{
  margin: 0px !important;
  padding: 0px !important;
  font-weight: 700;
  background-color: white !important;
  border: 1px solid black !important;
}

.pdfTable_head_row_fake_cell{
  margin: 0px !important;
  padding: 0px !important;
  background-color: white !important;
  border: 0px solid black !important;
  height: 30px;
}

.pdfTable_footer_row_fake_cell{
  margin: 0px !important;
  padding: 0px !important;
  background-color: white !important;
  border: 0px solid black !important;
}

.pdfTable_body_row_cell{
  margin: 0px !important;
  padding: 0px !important;
  font-weight: 500;
  background-color: white !important;
  border: 1px solid black !important;
}

.buttons_with_space{
  margin-right: 10px;
}

.buttons_with_space_header{
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  max-width: 200px;
  height: 35px;
}

.ButtonsMarketCategories {
  font-size: small;
  background-color: #2e8337;
  padding-top: 1px;
  padding-bottom: 1px;
}

.ButtonsMarketCategories.chosen {
  background-color: #fdbf33;
  color: black;
}

.ColumnsMarketCategories {
  padding: 2px;
}

.modal-header .close{
  color: white;
}

.buttons_with_half_space{
  margin-right: 5px;
}

.footer_buttons{
  // background-color: white;
  height: 100%;
  border: 0px;
  border-right: 1px solid;
  border-radius: 0%;
  font-size: large;
  :hover{
    background-color: inherit;
  }
}
td.MuiTableCell-root.MuiTablePagination-root{
  border: 0px;
  color: white;
}

.footer_buttons_main_screens{
  margin-right: 15px;
  background-color: black;
  color: white !important;
}

.footer_buttons_main_screens.left {
  margin-left: 50px;
}

.footer_buttons.chosen {
  background-color: #fdbf33;
  color: black;
}


.zero_margin_bottom{
  margin-bottom: 0px !important;
}

.aligned_row{
  align-items: center;
}

.zero_margin_one_padding_column_test{
  padding: 2px !important;
  margin: 0px !important;
}


.zero_padding_margin{
  padding: 0px !important;
  margin: 0px !important;
  .hide_bettslip_column{
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  .centralize_when_small{
    // justify-content: inherit;
    align-items: center;
    background-color: #2e8337;
    @media screen and (max-width: 992px) {
      justify-content: flex-start;
    }
  }
  .theHeaderClass{
    // background-color: #dddddd;
    background-color: #fdbf33;
    height: var(--header_height);
    min-height: var(--header_height);
    // border-right-width: inherit;
    border-right: 1px solid #1d5222;
    border-bottom: 0px;
    @media screen and (max-width: 992px) {    
      height: var(--small_header_height);
    }
  }

  .centerLogo{
    color: #ffffff;
    height: 120px;
  }

  .centerLogo_initial{
    color: #ffffff;
    margin-left: 30px;
    @media screen and (max-width: 992px) {    
      display: none;
    }
  }

  .System_Button_Numbers{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    padding: 0px;
  }

  .System_Button_Numbers:hover{
    background-color: #fdbf33;
    border-color: #fdbf33;
  }

  .MatchOdds_FirstCell{
    @media screen and (max-width: 992px) {    
      width: 350px !important;
    }
  }

  .cardBodyStyle {
    height: calc(var(--innerHeight) - var(--header_height));
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height));
    }
  }

  .MainCardHeight {
    margin: 0px;
    height: calc(var(--innerHeight) - var(--header_height) - var(--table_footer_height));
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height));
    }
  }

  .MainCardBodyHeight {
    padding: 0px;
    background-color: #f0f5f0ad;
    height: calc(var(--innerHeight) - var(--header_height) - var(--table_footer_height));
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height));
    }
  }

  .MainTableContainer{
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 0%;
    height: calc(var(--innerHeight) - var(--header_height) - var(--table_footer_height) - 2px);
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height) - 2px);
    }
  }

  .card-header, .card-footer{
    border-radius: 0%;
  }

  .MainTableContainerCollapse{
    overflow-y: hidden;
    overflow-x: hidden;
    height: calc(var(--innerHeight) - var(--header_height) - var(--table_footer_height) - 2px);
    @media screen and (max-width: 992px) {    
      height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height) - 2px);
    }
  }

  .MainTableContainerCollapse_Paper{
    overflow-y: auto;
    overflow-x: hidden;
    height: inherit;
  }


  .MainFinalRow{
    align-items: center;
    height: var(--table_footer_height);
    background-color: #2e8337;
    border-right: 1px solid;
    @media screen and (max-width: 992px) {
      height: var(--small_table_footer_height);
    }
  }
}

.MuiTableCell-root.hide_this_Cell{
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.MuiTableCell-root.Only_Cell_left{
  @media screen and (max-width: 992px) {
    vertical-align: baseline;
  }
}

.MainHeaderRow{
  color: #ffffff;
  height: 50px;
  @media screen and (max-width: 992px) {
    height: 70px;
  }
}

.c-sidebar {
  width: 50%;
  .c-sidebar-nav-dropdown-toggle::after{
    background-size: 40px;
    height: 40px;
    width: 40px;
    flex: initial;
  }
}

.c-sidebar-nav-divider {
  background-color: white;
  height: 1px;
  margin-right: 0px;
  margin-left: 0px;
}

html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
  margin-left: -50%;
}


.c-sidebar-nav-dropdown {
  // background-color: white;
  // height: 1px;
  // margin-right: 0px;
  // margin-left: 0px;
  font-size: 50px;
}

.c-sidebar-nav-icon {
  height: 50px;
  margin: 0px 20px 0px 10px !important;
}

.c-sidebar-nav-link {
  white-space: break-spaces;
}


.SportsImagesSidebar {
  width: 60px;
  margin-right: 20px;
}

.MuiTableCell-root.CollapseRowButtonCell{
  padding-right: 5px;
  padding-left: 5px;
  display: flex;
  @media screen and (max-width: 992px) {
    padding-right: 8px;
    // font-size: small;
  }
}

.black_ToPrint {
  color: black !important;
  font-weight: 900 !important;
}

.white_ToPrint {
  color: white !important;
  font-weight: 900 !important;
  background-color: black;
}

.MuiTableCell-root.CollapseRowButtonCell_line{
  padding-right: 0px;
  padding-left: 5px;
  // display: flex;
  @media screen and (max-width: 992px) {
    padding-right: 8px;
    // font-size: small;
  }
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.Mui-disabled.Mui-disabled{
  display: none;
}

.MuiTableRow-root.CollapseRowTableRow{
  background-color: #27722f;
  color: white;
  cursor: pointer;
}

.CollapseRowButtons {
  text-align-last: justify;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100% !important;
  @media screen and (max-width: 992px) {
    // font-size: xx-small;
  }
}


.MuiTableCell-root.CollapseRowGreenTableCell {
  color: #ffffff !important;
  padding: 5px 8px;
  display: inline-flex;
  align-items: center;
  height: 41px;
  width: 100%;
  font-weight: 600;
  cursor: pointer !important;
  @media screen and (max-width: 992px) {
    font-size: 50px;
  }
}

.MuiTableCell-root.CollapseRowSmallButtonCell{
  // padding-right: 8px;
  cursor: pointer;
  border-color: black;
  // @media screen and (max-width: 992px) {
    
  //   // font-size: small;
  // }
}

.CategoriesSmallLeagues {
  margin: 0rem;
  font-size: 50px;
  @media screen and (max-width: 992px) {
    // font-size: 1rem;
  }
}


.MuiSvgIcon-root.CategoriesSmall_Arrow {
  @media screen and (max-width: 992px) {
    font-size: 70px;
  }
}

.MatchOddsSpan {
  color: black;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-left: 26px;
  padding-right: 10px;
  @media screen and (max-width: 992px) {
    padding-left: 0px;
    font-size: 40px;
  }
}

.LockInRows{
  @media screen and (max-width: 992px) {
    font-size: 60px !important;
  }
}

.MatchOddsSpan_Header{
  font-size: smaller;
  padding: 0px 10px 0px 26px;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.zero_padding_margin.MAIN_SCREEN_COLUMN_LEFT{
  flex: 0 0 78%;
  max-width: 78%;
  @media screen and (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.zero_padding_margin.hide_bettslip_column.MAIN_SCREEN_COLUMN_RIGHT{
  flex: 0 0 22%;
  max-width: 22%;
}

.zero_padding_margin.TICKET_SCREEN_COLUMN_LEFT{
  flex: 0 0 78%;
  max-width: 78%;
}

.zero_padding_margin.TICKET_SCREEN_COLUMN_RIGHT{
  flex: 0 0 22%;
  max-width: 22%;
}



.Column_left_table_footer{
  // margin-left: 50px;
  padding: 0px;
  height: 100%;
}


.MuiIconButton-root.TheLockCodeButton:focus {
  outline-width: 0px !important;
}


.card-body{
  padding: 1.25rem;
  @media screen and (max-width: 992px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.MuiFormControl-fullWidth{
  min-width: 180% !important;
}



.Date_Bar_Textfield .MuiInputBase-input{
  padding-right: 6px !important;
  padding-left: 6px !important;
}

.Date_Bar_Textfield .MuiInputBase-root{
  border: 1px solid black !important;
}

.Date_Bar_Textfield fieldset{
  border: none !important;
}


.Match_Search_Bar_Textfield fieldset{
  border: none !important;
}

.Match_Search_Bar_Textfield .MuiAutocomplete-input{
  cursor: default;
}

.Match_Search_Bar_Textfield .MuiAutocomplete-input{
  cursor: default;
}

.Match_Search_Bar_Textfield .MuiIconButton-root{
    cursor: default;
    color: #2e8337 !important;
    padding: 0px !important;
}
.Match_Search_Bar_Textfield .MuiIconButton-root:hover{
  background-color: #2e8337 !important;
}

.Match_Search_Bar_Textfield .MuiInputBase-root{
  cursor: default;
}

.dropdown-menu.show.DropDownMenuUser{
  margin-top: 10px !important;
}


.CollapseRowHeadline {
  margin-bottom: 0px;
  margin-right: 10px;
  @media screen and (max-width: 992px) {
    // font-size: 1rem;
  }
}

.CollapseRowCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  @media screen and (max-width: 992px) {
    justify-content: center;
  }
}

.img_table {
  height: 25px,;
  width: 40px;
  border: 0.1px solid;
  border-color: black;
}

.MuiTableCell-root.Time_Date_Cell {
  width: 50px;
  padding: 6px 6px 6px 6px ;
  // font-size: inherit;
  @media screen and (max-width: 992px) {
    width: 100px;
    padding: 6px;
    font-size: 30px;
  }
}

.ReactVirtualized__Table__headerRow{
  display: inline-flex;
  background-color: #2e8337;
}

// .ReactVirtualized__Table__headerColumn:has(.MatchMembers_header) {
//   flex: auto;
//   border-bottom: 1px solid rgba(224, 224, 224, 1);
// }

.MatchMembers_header {
  flex: auto;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}



.ReactVirtualized__Table__headerColumn{
  height: 50px;
}

.ReactVirtualized__Table__row {
  display: inline-flex;
}

.ReactVirtualized__Table__row:hover {
  background-color: #dae9db;
}

.ReactVirtualized__Table__row.CollapseRowModify{
  display: flex;
  background-color: #1E5423;

  .ReactVirtualized__Table__rowColumn{
    height: 41px;
  }
}

// .ReactVirtualized__Table__rowColumn:has(.MatchMemebers_body) {
//   flex: auto;
// }
.MatchMemebers_body {
  flex: auto;
}


.ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn .MuiTableCell-body{
  height: 70px;
  vertical-align: middle;
}

// .ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn .MuiTableCell-body:has(.MatchMemebers_body) {
//   flex: auto;
// }


.ReactVirtualized__Grid__innerScrollContainer{
  width: 100% !important;
}

@media print {
  div {
    background: visible !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
}

.MuiTableCell-root.CategoriesTableCell {
  padding: 2px;
}

.disapear_when_small{
  @media screen and (max-width: 992px) {
    display: none;
  }
}


.MuiButtonBase-root.appear_when_small{
  display: none;
  margin-left: 10px;
  @media screen and (max-width: 992px) {
    display: inherit;
  }
}
.MuiSvgIcon-root.MenuWhenSmall{
  color: white;
  font-size: 60px;
}

.column_appear_when_small{
  display: none;
  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
}


.label.disapear_when_small{
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.UserDropdown{
  display: flex;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.RefreshButton{
  margin-left: 30px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.MuiIconButton-root.RefreshIconButton{
  display: none ;
  color: white;
  margin-right: 10px;
  @media screen and (max-width: 992px) {
    display: inline-flex ;
  }
}

.MuiIconButton-root.RefreshIconButton:focus{
  outline-width: 0px !important;
}

.MuiButtonBase-root.disapear_when_small {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.CategoriesMainButtons_div{
  text-align: right;
  @media screen and (max-width: 992px) {
    text-align: center;
  }
}

.CategoriesMainButtons {
  margin: 0.3rem;
  @media screen and (max-width: 992px) {
    margin: 0.1rem;
    padding: 3px 5px;
  }
}

.Sport_Country_League_span {
  // width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 600px;
}

.Sport_Country_League_subspan1 {
  // max-width: 100px;
  font-size: 12px;
  @media screen and (max-width: 992px) {
    font-size: 30px;
    display: none;
  }
}

.Sport_Country_League_subspan2 {
  font-weight: 900;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  @media screen and (max-width: 992px) {
    font-size: 30px;
  }
}

.Sport_Country_League_subspan2_collapse {
  font-weight: 900;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
  @media screen and (max-width: 992px) {
    font-size: 30px;
  }
}

.Sport_Country_League_subspan3 {
  font-weight: 900;
  font-size: 15px;
  margin-left: 15px;
  @media screen and (max-width: 992px) {
    font-size: 30px;
    display: none;
  }
}
.Sport_Country_League_subspan3.Sport_Country_League_subspan3_Collapse {
 font-size: 10px;
}

.Modal_spinner .modal-content {
  width: 21em;
  padding: 2em;
  background-color: #dae9db;
  @media screen and (max-width: 992px) {
    width: 24em;
    padding: 2em;
  }
}

.Modal_iframe .modal-dialog.modal-dialog-centered.modal-xl{
  max-width: 100%;
}
.iframe_CollapseRows {
  height: calc(var(--innerHeight) - var(--header_height));
  width: 100%;
  @media screen and (max-width: 992px) {    
    height: calc(var(--innerHeight) - var(--small_header_height) - var(--small_table_footer_height));
  }
}

.Print_Row_100{
  display: block;
  align-items: center;
  justify-content: center;
  width: 100% !important;
}

.Print_100{
  width: 100% !important;
}

.Modal_spinner.d-block {
  display: flex !important;
  justify-content: center;
}

.Spinner_style {
  animation-duration: 2.0s;
  border-width: 1.5em;
  width: 17em;
  height: 17em;
  @media screen and (max-width: 992px) {
    width: 20em;
    height: 20em;
    border-width: 2em;
  }
}

.button_zero_padding{
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 45px;
  @media screen and (max-width: 992px) {
    width: 100px;
    font-size: 30px;
  }
}

.button_zero_padding.button_left {
  margin-right: 5px;
  @media screen and (max-width: 992px) {
    margin-right: 0px;

  }
}

.MuiFormControl-root.dropdown_header_main_markets {
  margin-right: 10px;
  min-width: 150px;
  max-width: 250px;
  overflow: ellipsis;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.MuiFilledInput-root {
  background-color: #dae9db !important;
  border: 2px solid black;
  border-radius: 5px;
  max-width: 350px;
}

.MuiSelect-filled.MuiSelect-filled{
  height: 30px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
}

.MuiFilledInput-underline::after{
  border-bottom: 2px !important;
}


.button_header_main_markets {
  margin-right: 5px;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.button_header_live_buttons {
  margin-left: 10px;
  width: 100px;
  height: 35px;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.column_right_left_0{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card{ 
  border-radius: 0% !important;
  margin-bottom: 0px;
}

.dropdown-menu.show{
  transform: translate3d(0px, 28px, 0px) !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.columns_5_padding{
  padding: 5px;
}


.badge_modifications {
  border-radius: 50%;
  padding: 0.3em;
  margin-right: 5px;
  font-size: 12px;
  @media screen and (max-width: 992px) {
    display: none;
    font-size: 0.875rem;
  }
}

.badge_modifications_collapse {
  border-radius: 50%;
  padding: 0.3em;
  margin-right: 5px;
  font-size: 12px;
  // @media screen and (max-width: 992px) {
  //   display: none;
  //   font-size: 0.875rem;
  // }
}

.close {
  font-weight: 1000;
  font-size: 1.9rem;
  opacity: 1;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.TicketsShowSlipsHeadCells {
  color: white !important;
  border-right: 1px solid black !important;
  border-bottom: 0px !important
}
.TicketsShowSlipsBodyCells {
  border: 1px solid
}

.TicketsShowSlipsBodyCells.TotalRowStatements {
  color: white;
}


.superSmallFont {
  font-size: small;
}