// @import url('https://fonts.googleapis.com/css2?family=Roboto+Serif');

// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}
body, .btn, .MuiTypography-colorInherit, .MuiTableCell-head{
  font-weight: 600 !important;
}

body, .btn, .MuiTypography-colorInherit, .MuiTableCell-head, .MuiTableCell-root, .MuiTypography-body1, .MuiBadge-badge, .MuiInputBase-input {
  // font-family: 'Courier New', monospace !important;
  // font-family: Courier, monospace !important;
  // font-family:'Franklin Gothic Medium', sans-serif !important;
  // font-family: 'Arial Narrow', sans-serif !important;
  // font-family: 'Gill Sans', sans-serif !important;
  // font-family: 'Gill Sans MT', sans-serif !important;
  // font-family: Calibri, sans-serif !important;
  // font-family: 'Lucida Sans Regular', sans-serif !important;
  // font-family: 'Segoe UI', sans-serif !important;
  // font-family: Tahoma, sans-serif !important;
  // font-family: Geneva, sans-serif !important;
  // font-family: 'Lucida Sans Unicode', sans-serif !important;
  // font-family: 'Lucida Grande', sans-serif !important;
  // font-family: 'Lucida Sans', sans-serif !important;
  // font-family: Helvetica, sans-serif !important;
  // font-family: Cambria, serif !important;
  // font-family: Cochin, serif !important;
  // font-family: Georgia, serif !important;
  // font-family: Times, serif !important;
  // font-family: 'Times New Roman', serif !important;
  // font-family: 'Arial Narrow Bold', sans-serif !important;
  // font-family: Verdana,  sans-serif !important;
  // font-family: 'Trebuchet MS', sans-serif !important;
  // font-family: Haettenschweiler, sans-serif !important;
  font-family: Arial, sans-serif !important;
  // font-family: 'Roboto Serif', serif !important;
}